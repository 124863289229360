<script setup lang="ts">
import { ErrorMessage, useField } from 'vee-validate'

import Multiselect from '@vueform/multiselect'

import type { MutationOptions, Rules } from '~/components/BaseForm/types'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    labelClass?: string
    labelHtml?: string
    labelText?: string
    mutationOptions?: MutationOptions
    name: string
    rules?: Rules
    wrapperClass?: string
    modelValue?:
      | Record<string, unknown>
      | string[]
      | number[]
      | string
      | number
      | null
  }>(),
  {
    labelClass: 'base-form-label',
    labelHtml: '',
    labelText: '',
    modelValue: null,
    mutationOptions: () => Promise.resolve([]),
    rules: '',
    wrapperClass: 'w-full mb-4',
  },
)

const rules = toRef(props, 'rules')
const multiselect = ref('')

const { value: inputValue, errors } = useField(props.name, rules, {
  initialValue: props.modelValue,
})

const isError = computed(() => Boolean(errors.value.length))
</script>

<template>
  <div :class="wrapperClass">
    <label
      v-if="labelText"
      :class="labelClass"
      data-testid="multiselect-label-text"
    >
      <BaseFormComponentsBaseFormLabel
        :label="labelText"
        :label-html="labelHtml"
      />
      <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
    </label>

    <Multiselect
      ref="multiselect"
      v-model="inputValue"
      :class="[{ 'base-form--hasError': isError }]"
      :delay="0"
      :filter-results="false"
      :min-chars="1"
      :options="mutationOptions"
      :searchable="true"
      v-bind="$attrs"
    >
      <template #nooptions>
        <li class="multiselect-option is-disabled">
          {{ $t('global.noResult') }}
        </li>
      </template>

      <template #afterlist>
        <slot name="afterlist" />
      </template>
    </Multiselect>

    <error-message :name="name" as="span" class="base-form--error" />
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.multiselect {
  min-height: 51px;
  @apply border-gray-300 rounded-sm;
}

:root {
  --ms-tag-bg: #d4a45f;
  --ms-option-bg-selected: #d4a45f;
  --ms-option-bg-selected-pointed: #d4a45f;
  --ms-ring-color: #dbbc8f40;
}
</style>
